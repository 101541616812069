*{
    font-size: 12px;
    font-family: 'LatoRegular';
}

.bg-img{
    background-image: url('../img/she_01.jpg');
    background-size: cover;
    min-height: 95vh;
}

.bg-blue{
    background-color: rgb(31, 31, 92);
}

.underconstruction{
    margin-top:20%;
    font-size: 20px;
}