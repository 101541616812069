@font-face {
    font-family: 'RubicGlitch-Regular';
    src: url('../css/fonts/RubikGlitch-Regular.ttf');
}

@font-face {
    font-family: 'LatoLight';
    src: url('../css/fonts/Lato-Light.ttf');
}

@font-face {
    font-family: 'LatoRegular';
    src: url('../css/fonts/Lato-Regular.ttf');
}

@font-face {
    font-family: 'LatoBold';
    src: url('../css/fonts/Lato-Bold.ttf');
}

@font-face {
    font-family: 'LatoBlack';
    src: url('../css/fonts/Lato-Black.ttf');
}